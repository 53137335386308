.chat_wrapper {
    height: calc( 100vh - 64px );
    overflow: hidden;
}
.ChattingUserWrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.ChattingUserWrap_inner {
    flex: 1 1 auto;
    overflow: auto;
}

.chat_header {
    position: sticky;
    top: 0;
    z-index: 9;
    padding: 10px 0px;
    background: #fff;
    display: flex;
}
.messageTyper_wrap .messageTyper {
    width: 100%;
    flex: 1;
}
.messageTyper_wrap {
    position: sticky;
    bottom: 0;
    gap: 10px;
    border-top: 1px solid #f6f6f7;
    border-radius: 20px 20px 0 0;
    background: #f6f6f7;
    /* flex: 1; */
}
@media ( max-width: 1199px ){
    .ChattingUserWrap{
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
}